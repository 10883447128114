:host ::ng-deep {
  .widget-call-chat_msgs {
    padding: 10px 0px !important;
  }

  @media screen and (max-width: 768px) {
    .widget-call-chat-footer {
      left: 36px !important;
      right: 36px !important;
      bottom: 40px !important;
    }
  }
}

.messages_none__text {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}

.room {
  overflow: hidden;
  // минус высота шапки
  height: calc(100% - 55px);

  display: none;

  &.isActive {
    display: block;
  }
}

.search {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  .search-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    input {
      height: 38px;
      line-height: 38px;
      border-radius: 6px;
      padding: 0 12px;
      display: block;

      font-weight: 400;
      font-size: 16px;

      color: rgba(68, 68, 79, 1);

      &::placeholder {
        color: rgba(146, 146, 157, 1);
      }

      border: 1px solid rgba(150, 157, 146, 0.6);

      &:focus {
        border: 1px solid rgba(103, 138, 255, 0.6);
        box-shadow: 0px 0px 6px rgb(103, 138, 255);
      }
    }
  }
}

.search-results {
  padding-top: 8px;
}

.search-result {
  display: flex;
  flex-direction: column;
  padding: 16px;

  &+& {
    margin-top: 8px;
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;

    justify-content: space-between;

    margin-bottom: 8px;

    .time {
      font-size: 14px;
      line-height: 14px;
      color: rgba(146, 146, 157, 1);
      font-weight: 400;
    }
  }

  .body {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-weight: 500;
    font-size: 14px;
    line-height: 14px;

    .author {
      color: rgba(103, 138, 255, 1);
      max-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;

      white-space: nowrap;
      flex-shrink: 0;

      max-width: 100px;
    }

    .text {
      font-weight: 400;
      max-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;

      white-space: nowrap;
    }

    .image {}
  }
}

.search-result-header {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 55px;

  margin-right: 15px;
  flex-shrink: 0;

  padding-bottom: 8px;
  border-bottom: 1px solid rgba(146, 146, 157, 0.6);

  &+.messages__list {
    margin-top: 8px;
  }


  .title {
    font-size: 14px;
    line-height: 15px;
    font-weight: 500;
  }

  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    &.toggle {
      &.collapsed {
        transform: rotate(180deg);
      }
    }

    &>svg {
      width: 16px;
      height: 16px;
    }
  }
}



.messages {
  background: #fff;
  border-radius: 20px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  min-height: var(--mainBlockMinHeightDesktop);
  height: calc(100vh - (var(--headerHeight) + 100px));

  @media screen and (max-width: 1279px) {
    height: calc(100vh - (var(--headerHeight) + var(--mainBlockPaddingMobile) * 2));
  }

  &.isRoom {
    @media screen and (max-width: 768px) {
      height: calc(100vh - (var(--headerHeight) + 32px));

      .messages__left {
        display: none;
      }

      .messages__right {
        display: flex;
        padding-left: 0;
      }

      .messages_list {
        padding: 0;
      }
    }
  }

  @media screen and (max-width: 768px) {
    min-height: auto;
    display: block;

    padding: 24px 20px;
  }

  .messages-header {
    height: 50px;
    margin-bottom: 30px;

    display: flex;
    align-items: center;
    flex-shrink: 0;

    .controls {
      display: flex;
      flex-direction: row;
      gap: 16px;

      .control-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;
        border-radius: 100%;
        font-weight: 400;
        background-color: #fff;

        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.14);

        cursor: pointer;

        &>svg {
          width: 17px;
          height: 17px;
        }
      }

      .control {}
    }
  }

  .messages-body {
    display: flex;
    flex-grow: 1;
    position: relative;
    // 80px высота шапки чата + отступ 50 + 30
    height: calc(100% - 80px);

    @media screen and (max-width: 768px) {
      height: calc(100% - 80px - var(--group-lesson-chat-footer-height));
    }

    .messages-body-header {
      display: flex;
      align-items: center;
      gap: 12px;
      height: 55px;
      flex-shrink: 0;

      border-bottom: 1px solid rgba(146, 146, 157, 0.6);
      padding-bottom: 8px;
    }
  }
}

.messages-info {
  background: rgba(103, 138, 255, 0.1);
  padding: 20px;
  border-radius: 6px;
  color: rgba(68, 68, 79, 1);

  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  position: relative;

  margin-right: 15px;
}

.messages__left {
  border-right: 1px solid #f1f1f5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 340px;
  flex-grow: 1;
  flex-shrink: 0;

  &.has-rooms {
    @media screen and (max-width: 768px) {
      &>.messages_none {
        display: none;
      }
    }
  }


  @media screen and (max-width: 768px) {
    border-right: 0;
    width: 100%;
    flex-grow: 1;
    max-width: 100%;
    padding-right: 0;
  }

  @media screen and (min-width: 768px) {
    &>.messages_none {
      display: none;
    }
  }
}

.messages__right {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  flex-grow: 1;

  max-width: 660px;

  @media screen and (max-width: 768px) {
    display: none;
  }

  .icon-menu {
    width: 24px;
    height: 24px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 3px;
      height: 15px;
    }
  }
}

.messages__back {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }
}

.messages__top {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.messages__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
}

.messages .mat-menu-content {
  background: #f00;
}

.messages .mat-menu-content .mat-menu-item {
  display: flex;
}

.messages .mat-menu-content .mat-menu-item svg-icon-sprite {
  margin-right: 1rem;
  height: 2.4rem;
  width: 2.4rem;
}

.messages_none {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.messages_none__wrap {
  max-width: 35rem;
  text-align: center;
}

.messages_none__icon {
  margin-bottom: 16px;
}

.messages_none__title {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

.messages_none__text {
  margin-bottom: 32px;
}

.messages_input {
  flex: 0 0 8rem;
  position: relative;
  border-top: 1px solid #f1f1f5;
}

.messages_input #attachMessage {
  position: absolute;
  left: -999rem;
  top: -999rem;
}

.messages_input .button-attachMessage {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 0.5rem;
  background: #f1f1f5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 8rem;
  top: 2.5rem;
  cursor: pointer;
}

.messages_input .button-attachMessage svg {
  width: 2.4rem;
  height: 2.4rem;
  object-fit: contain;
}

.messages_input #attach {
  position: absolute;
  left: -999rem;
  top: -999rem;
}

.messages_input .button-attach {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 0.5rem;
  background: #f1f1f5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 8rem;
  top: 2.5rem;
  cursor: pointer;
}

.messages_input .button-attach svg {
  width: 2.4rem;
  height: 2.4rem;
  object-fit: contain;
}

.messages_input__button {
  width: 5.4rem;
  height: 5.4rem;
  background: #678aff;
  border-radius: 50%;
  display: block;
  position: absolute;
  right: 0;
  top: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.messages_input__button svg {
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  object-fit: contain;
}

.messages_input__textarea {
  margin: 0 10rem 0 0;
  padding: 2rem 1.5rem 1rem 1.5rem;

  textarea {
    width: 100%;
  }
}

.messages_input__files {
  position: absolute;
  display: flex;
  top: -10px;
  width: 99%;
  left: 0;
  z-index: 11;
}

.messages_input__files .new-message-block__bar {
  width: 100%;
  position: absolute;
  top: -10px;
  left: 0;
}

.messages_input__files__file {
  border-radius: 8px;
  padding: 0.4rem;
  font-size: 1rem;
  margin-left: 4px;
  background-color: #fff;
  color: #678aff;
  border: 1px solid #c5cdd8;
  max-width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.messages_input__files__file:hover {
  border: 1px solid #678aff;
  cursor: pointer;
}

.messages_menu__item {
  display: flex !important;
  align-items: center;
}

.messages_menu__item img {
  flex: 0 0 2rem;
  width: 2rem;
  height: 2rem;
}

.messages_menu__item span {
  margin-left: 1rem;
}

.messages_list {
  flex: 1 0 auto;
  padding: 0 3rem;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  height: calc(40rem - 16rem);
  overflow: auto;
}

.messages_list.keyboardDisplay {
  height: calc(100% - 7rem - 8rem);
}

.message_user {
  display: flex;
  align-items: center;

  gap: 16px;
}

.message_user__image {
  background: #ffc849;
  border-radius: 100%;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;

  color: #fff;
}

.message_user__image .avatar-image {
  width: 44px;
  height: 44px;
  border-radius: 100%;
}

.message_user__name {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.message {
  margin: 1rem 0;
  margin-bottom: 24px;
  padding: 0 0 0 5.5rem;
  position: relative;
}

.message__date {
  text-align: center;
}

.message__date span {
  border-radius: 0.4rem;
  background: #f3f4f9;
  color: #92929d;
  font-size: 1.2rem;
  font-weight: 500;
  height: 3rem;
  line-height: 3rem;
  padding: 0 1.5rem;
  display: inline-block;
}

.message__image {
  position: absolute;
  font-size: 0;
  top: 0;
  left: 0;
  background: #ffc849;
  border-radius: 50%;
  width: 4.5rem;
  height: 4.5rem;
  vertical-align: middle;
  text-align: center;
  font-size: 2rem;
  text-transform: uppercase;
  line-height: 4.5rem;
  color: #fff;
}

.message__image img {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
}

.message__nopreview {
  width: 10rem;
  height: 10rem;
}

.message__nopreview__text {
  text-align: center;
  font-size: 1.2rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d1d5e8;
}

.message__text {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  max-width: 56rem;
  width: fit-content;
  padding: 1rem 1.5rem;
  background: #f3f4f9;
  border-radius: 0 2rem 2rem 2rem;
  text-align: left;
}

.message__text__item {
  width: 100%;
}

.message__text .text {
  word-break: break-word;
}

.message__text .text a {
  color: #000;
}

.message__text .ng-star-inserted {
  display: inline-block;
}

.message__text .ng-star-inserted+,
.message__text .ng-star-inserted .ng-star-inserted {
  margin-left: 1.5rem;
}

.message__text .image {
  width: 10rem;
  height: 10rem;
  display: inline-block;
  font-size: 0;
  margin-bottom: 1.5rem;
}

.message__text .image:last-child {
  margin-bottom: 0;
}

.message__text .image img {
  border-radius: 1rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.message__text .file {
  justify-content: space-between;
  height: 5.6rem;
  display: flex;
  border-radius: 1rem;
  background: #fff;
  align-items: center;
  text-align: left;
}

.message__text .file__cloud {
  min-width: 36px;
  min-height: 36px;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

.message__text .file__cloud img {
  width: 3.6rem;
  height: 3.6rem;
}

.message__text .file__icon {
  min-width: 56px;
  background: #eff3ff;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message__text .file__icon img {
  max-width: 2rem;
  max-height: 2rem;
}

.message__text .file__info {
  margin-right: 1rem;
}

.message__text .file__name {
  display: inline-block;
  text-overflow: ellipsis;
  max-width: 10rem;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  color: #92929d;
}

.message__text .file__size {
  font-size: 1.2rem;
  color: #92929d;
  display: block;
}

.message__author {
  display: flex;
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
}

.message__author.multiTyping {
  display: flex;
  overflow: hidden;
}

.message__author .name {
  font-weight: bold;
  max-width: 70%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.message__author .time {
  color: #696974;
  margin-left: 1rem;
  font-size: 1.4rem;
}

.message.my {
  padding: 0 5.5rem 0 0;
}

.message.my .name {
  text-align: end;
}

.message.my .message__image {
  right: 0;
  left: auto;
}

.message.my .message__text {
  color: #fff;
  text-align: right;
  background: #678aff;
  border-radius: 2rem 0 2rem 2rem;
  margin-left: auto;
}

.message.my .message__text a {
  color: #fff;
}

.message.my .message__author {
  display: flex;
  flex-direction: row-reverse;
}

.message.my .message__author .time {
  margin-left: 0;
  margin-right: 1rem;
}

.messages_members {
  padding: 3rem;
}

.messages_members__title {
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.4rem;
  margin-bottom: 2rem;
}

.messages_members__list {
  overflow: auto;
  max-height: 40rem;
}

.messages_list_item {
  &+.search-result-header {
    margin-top: 8px;
  }
}

.messages__list {
  height: 100%;
  overflow-y: auto;
  padding-right: 15px;

  &.hide {
    display: none;
  }
}

.messages_members_item {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.8rem;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }
}

.messages_members_item__image {
  flex: 0 0 4.5rem;
  margin-right: 1rem;
  font-size: 0;
  width: 4.5rem;
  background: #ffc849;
  border-radius: 50%;
  height: 4.5rem;
  vertical-align: middle;
  text-align: center;
  font-size: 2rem;
  text-transform: uppercase;
  line-height: 4.5rem;
  color: #fff;
}

.messages_members_item__image .avatar-image {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
}

.messages_members_item__name {
  flex: 1 0 auto;
}

.messages_members_item__online {
  flex: 0 0 1rem;
}

.messages_members_item__online span {
  display: block;
  width: 1rem;
  height: 1rem;
  background: #82c43c;
  border-radius: 50%;
}

.messages_list_item {
  cursor: pointer;
  position: relative;

  .inner {
    padding: 16px;
    border-radius: 6px;
  }

  &:hover,
  &.current {
    .inner {
      background: rgba(103, 138, 255, 0.1);
    }

  }

  .messages_list_item__author {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 12px;
  }


  .messages_list_item__image {
    display: flex;
    position: relative;
    width: 44px;
    height: 44px;
    justify-content: center;
    line-height: 44px;
    background: #ffc849;
    border-radius: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    flex-shrink: 0;
    margin-top: -13px;

    .avatar-image {
      width: 44px;
      height: 44px;
      border-radius: 100%;
    }

    .is-online {
      width: 10px;
      height: 10px;
      border: 2px solid #fff;
      border-radius: 50%;
      position: absolute;
      top: 33px;
      left: 33px;
      background: rgba(149, 165, 184, 1);

      &.active {
        background: #3dd598;
      }
    }
  }
}

.messages_list_item__info {
  min-height: 56px;
  width: calc(100% - 44px);
}

.messages_list_item__text {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 28px;
  max-width: 100%;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: rgba(68, 68, 79, 1);
  word-break: break-word;
}

.messages_list_item__name {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  margin-bottom: 8px;

  .text {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    width: calc(100% - 50px);
  }

  .time {
    font-size: 14px;
    line-height: 14px;
    width: 50px;
    text-align: right;
    color: rgba(146, 146, 157, 1);
  }
}

.messages_list_item .badge {
  background: #ed3434;
  width: 2.3rem;
  height: 1.7rem;
  text-align: center;
  font-size: 1rem;
  color: #fff;
  display: inline-block;
  border-radius: 1.2rem;
  line-height: 1.5rem;
  font-weight: 400 !important;
  margin-left: 0.5rem;
  vertical-align: middle;
  position: absolute;
  top: 1.5rem;
  left: 1rem;
  border: 1px solid #fff;
}

.multi-type-image {
  left: 2rem;
}

.keyboardDisplay {
  height: auto;
}

.unread-messages-divider {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  border-bottom: 1px solid #e64545;
  color: #e64545;
}