$pageColor: black;
$pageFontSize: 1.4rem;
$pageLineHeight: 1.454545;
$pageBg: #c4cdd8;
$linkColor: black;
$selectionBg: black;
$selectionColor: white;
$redColor: #ed3434;
$blueColor: #678aff;
$greenColor: #3dd598;
$greyColor: #95a5b8;
$purpleColor: #6a6aad;
$yellowColor: #ffc849;
$darkBlueColor: #151d3a;
$darkGreyColor: #696974;
$successColor: #00bfa5;
$warnColor: #ff5722;

.title--32,
.global__title {
  font-size: 3.2rem;
  font-weight: 900;
  line-height: 3.6rem;
}

.title--24 {
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: 500;
}

.title--20 {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 500;
}

.title--18 {
  font-size: 1.8rem;
  line-height: 1.6em;
  font-weight: 500;
}

.text--18,
.global__text {
  font-size: 1.8rem;
  line-height: 1.6em;
}

.text--16 {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

html {
  height: 100%;
  font-size: 10px;
  font-family: 'Roboto';
  color: $pageColor;

  height: -webkit-fill-aviable;
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  html {
    font-size: calc(8.5px + 1.5 * (100vw - 320px) / 55);
  }
}

@media screen and (max-width: 1279.9px) {
  .add-blur {
    filter: blur(7px);
    -webkit-filter: blur(7px);
    -moz-filter: blur(7px);
    -o-filter: blur(7px);
    -ms-filter: blur(7px);
  }

  html {
    font-size: calc(10px + 10.45 * (100vw - 375px) / 392);
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  html {
    font-size: calc(6px + 2 * (100vw - 768px) / 256);
  }
}

@media (min-width: 1024px) and (max-width: 1152px) {
  html {
    font-size: 8px;
  }
}

@media (min-width: 1152px) and (max-width: 1440px) {
  html {
    font-size: calc(8px + 2 * (100vw - 1152px) / 288);
  }
}

_:default:not(:root:root),
html {
  animation: webkitfix 1s forwards infinite;
}

* {
  box-sizing: border-box;
  outline: none;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #d5d5dc #fff;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: #d5d5dc;
  border-radius: 5px;
  border: 3px solid #fff;
}

*::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0;
  height: 0;
}

a {
  cursor: pointer;
}

body {
  height: 100%;
  background: $pageBg;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto-Regular.woff2') format('woff2'),
    url('/assets/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto-Bold.woff2') format('woff2'), url('/assets/fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto-Medium.woff2') format('woff2'), url('/assets/fonts/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

app-root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  font-size: $pageFontSize;
  line-height: $pageLineHeight;
  font-weight: regular;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

@media screen and (max-width: 1279.9px) {
  app-root {
    // font-size: 1.6rem;
    overflow-x: hidden;
  }
}

.global {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.global__wrap {
  text-align: center;
  max-width: 59rem;
}

.global__icon {
  width: 7.8rem;
  height: 7.8rem;
  margin: 0 auto 2rem;
}

.global__title {
  margin-bottom: 2.3rem;
}

.global__text a {
  color: $blueColor;
  text-decoration: underline;
}

@media screen and (min-width: 1280px) {
  .global__text a:hover {
    text-decoration: none;
  }
}

.jivo-block-scroll {
  overflow: hidden;
}

.is-open-menu {
  height: 100%;
  overflow: hidden;
}

.logotype {
  width: 107px;
  height: 51px;
}

.logotype svg {
  width: 100%;
  height: 100%;
  display: block;
}

.page__center {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page__center .section {
  width: 100%;
}

.page__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.page__video video {
  width: 100%;
  height: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.333s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: bold;
}

img {
  max-width: 100%;
}

b,
strong {
  font-weight: bold;
}

a {
  color: $linkColor;
  text-decoration: none;
}

p {
  margin: 0;
}

p+p {
  margin: 1.6em 0 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

svg {
  width: 100%;
  height: 100%;
  display: block;
}

@media screen and (max-width: 1279.9px) {
  svg {
    width: auto;
    height: auto;
  }
}

button,
textarea,
input {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  padding: 0;
  outline: none;
}

.visually_hidden {
  position: absolute !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0);
  height: 1px !important;
  width: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  border: 0 !important;
}

@media tabletDesktop {
  .visually_hidden--desktop {
    position: absolute !important;
    overflow: hidden !important;
    clip: rect(0 0 0 0);
    height: 1px !important;
    width: 1px !important;
    margin: -1px !important;
    padding: 0 !important;
    border: 0 !important;
  }
}

@media screen and (max-width: 1279.9px) {
  .visually_hidden--mobile {
    position: absolute !important;
    overflow: hidden !important;
    clip: rect(0 0 0 0);
    height: 1px !important;
    width: 1px !important;
    margin: -1px !important;
    padding: 0 !important;
    border: 0 !important;
  }
}

.link {
  color: $redColor;
  box-shadow: inset 0 -0.08em currentColor;
}

@media screen and (min-width: 1280px) {
  .link:hover {
    box-shadow: none;
  }
}

.results_none {
  margin: 0 auto;
  max-width: 60rem;
  text-align: center;
}

.error_label {
  border: 1px solid #f00;
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: 1.4rem;
  color: #f00;
  margin-bottom: 1.5rem;
}

.error_label p {
  margin-bottom: 0;
}

.mat-progress-spinner.loader {
  display: inline-block !important;
}

.mat-progress-spinner.loader circle,
.mat-spinner.loader circle {
  stroke: #678aff !important;
}

.note {
  background: #7ca0ff;
  border-radius: 0 0 1.2rem 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6rem;
  width: 100%;
  margin-top: -3rem;
  margin-bottom: 2rem;
  position: relative;
}

@media screen and (max-width: 1279.9px) {
  .note {
    height: auto;
    padding: 1rem 2rem;
  }
}

.note__text {
  color: #fff;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.4rem;
  max-width: 65rem;
  margin: 0 auto;
  padding-bottom: 0.5rem;
}

@media screen and (max-width: 1279.9px) {
  .note__text {
    font-size: 1.5rem;
    line-height: 1.3em;
    text-align: left;
  }
}

.note__close {
  color: #fff;
  width: 2.5rem;
  height: 2.5rem;
  display: block;
  position: absolute;
  right: 2rem;
  top: 1.5rem;
}

@media screen and (max-width: 1279.9px) {
  .note__close {
    width: 1.5rem;
    height: 1.5rem;
    right: 1.5rem;
  }
}

.note__close img {
  color: #fff;
  width: 2.5rem;
  height: 2.5rem;
}

@media screen and (max-width: 1279.9px) {
  .note__close img {
    width: 1.5rem;
    height: 1.5rem;
  }
}

body,
html {
  overflow-anchor: none;
}

.mobile-height {
  // это как я понял хак который изменяет высоту документа под мобильные устройства, вычитая адресную строку и прочие браузерные элементы
  // --vh вычисляется в коде, исключает внешние элементы такие как адресная строка браузера, клавиатура и прочее
  // height: calc(var(--vh, 1vh) * 100); // что это и зачем?
  height: -webkit-fill-aviable;

  &.is-overflow-mobile {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }

}

.mat-label--required:after,
.text--required:after {
  content: ' *';
  color: #ff4081;
}

.file-input-hidden {
  display: none;
}

.datetime-devider {
  width: 5px;
  height: 5px;
  background-color: #92929d;
  border-radius: 50%;
  margin-left: 9px;
  margin-right: 9px;
}

@-moz-keyframes webkitfix {
  100% {
    z-index: auto;
  }
}

@-webkit-keyframes webkitfix {
  100% {
    z-index: auto;
  }
}

@-o-keyframes webkitfix {
  100% {
    z-index: auto;
  }
}

@keyframes webkitfix {
  100% {
    z-index: auto;
  }
}

// Для звонка меняем BG main и отключаем padding
main.main {
  // background: #2C3241;
  // padding: 0px !important;
}