.ezteach-calendar-popup {
  font-family: Roboto;
  color: #44444f;
  font-size: 16px;
  line-height: 22px;
  display: block;
  position: relative;
  max-width: 435px;
  margin: 0 auto;
  width: 100%;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 3px 9px 104px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 15px 10px;
  animation: fadeIn 0.5s linear;

  @media screen and (max-width: 768px) {
    max-width: none;

    height: 100%;
    // width: 100%;
  }
  &.mobile{
    max-height: calc(100vh - 30px);
    overflow-y: auto;
    @media (orientation: portrait){
      max-height: calc(100vh - 180px);
    }
  }
  @media screen and (max-width: 460px) {
    width: 100%;
    max-height: calc(100vh - 30px);
    overflow-y: auto;
  }

  &-panel {
    padding: 15px;
    min-width: 340px;
    max-height: 100%;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    right: initial !important;
    bottom: initial !important;
    transform: translate3d(-50%, -50%, 0);

    @media screen and (max-width: 460px) {
      width: 100%;
    }

    &--hidden {
      display: none;
    }
  }

  &__columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }

  &__discipline {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    margin-bottom: 15px;

    &-prefix {
      width: 3px;
      height: 14px;
      background: #678aff;
      border-radius: 1.5px;
      transform: rotate(-180deg);
    }

    &-name {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: #44444f;
    }
  }

  &__time-field {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding-left: -2px;

    &.mobile {
      margin-bottom: auto;
    }

    &.range {
      margin-bottom: 24px;
    }

    img {
      display: block;
      // height: 18px;
      margin-right: 5px;
      // padding-bottom: 5px;
      height: 100%;
    }

    .without-underline {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  &__classroom-field {
    color: black;

    .ezteach-calendar-popup-notice {
      color: #678aff;
      font-size: 12px;
    }
  }

  &__button {
    width: 100%;
    margin: 5px auto;
  }

  & .mat-checkbox-layout {
    white-space: normal !important;
    align-items: flex-start;
    margin-bottom: 1rem;

    .mat-checkbox-label {
      margin: 0;
      padding: 0;
      font-size: 12px;
      line-height: 16px;
    }
  }

  & .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #3dd598 !important;
  }

  & .mat-checkbox-inner-container {
    margin: 0;
    margin-right: 8px;
  }

  & .mat-select {
    margin-bottom: 1rem;
  }

  & .info-field {
    line-height: 16px;
    display: flex;
    background: #c4c4c421;
    border-radius: 8px;
    padding: 8px 10px;
    font-size: 12px;
    margin-bottom: 15px;

    .info-field__icon {
      margin-right: 5px;

      svg {
        width: 13px;
      }
    }

    .info-field__inner-wrapper {
      display: flex;
      flex-direction: column;

      .inner-wrapper__text {
        margin-bottom: 5px;
      }

      .nav-item {
        margin: 0;
        padding: 0;

        &__link {
          color: #678aff;
          border-bottom: 1px solid #678aff;
        }
      }
    }
  }

  &__time__from {
    width: 90%;
  }

  &__time-range-wrapper,
  &__time-range-wrapper>ezteach-time-range {
    width: 100%;
  }

  .container {
    max-width: 416px;
    width: 100%;
    padding: 0 6px;
    border-radius: 16px;
    background: #fff;

    &.mobile {
      display: flex;
      height: 100%;
      flex-direction: column;
      width: auto;
    }

    .mat-select-trigger {
      height: 44px;
      background: #f1f1f5;
      border: 1px solid #e5e5eb;
      border-radius: 8px;
      padding-right: 10px;
    }

    .mat-select-value {
      vertical-align: middle;
      padding-left: 16px;
      font-size: 16px;
      color: #44444f;
    }
  }

  .mobile {
    .ez-date-time-range-wrapper {
      flex-direction: column;
    }
  }

  .create-group-lesson,
  .create-slot-lesson,
  .edit-group-lesson,
  .edit-slot-lesson {
    max-height: 100%;
    overflow-y: auto;
  }

  .edit-group-lesson .access-block {
    margin-bottom: 20px;
  }

  .header-row {
    display: flex;
    justify-content: center;
    color: #44444f;
    position: relative;
  }

  .title {
    padding: 0;
    font-weight: 600;
    font-size: 20px;
    flex-grow: 1;
  }

  .successTitle {
    margin-bottom: 15px;
  }

  .close-button {
    display: flex;
    cursor: pointer;
    width: 20px;

    scale: 0.7;
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .mat-tab-label {
    padding: 12px 9px !important;
    min-width: 126px !important;
    height: auto !important;
    opacity: 1 !important;
  }

  .mat-tab-label:nth-child(1) {
    margin-right: 42px;
  }

  .mat-tab-label.mat-tab-label-active {
    color: #678aff !important;
  }

  .mat-tab-label:not(mat-tab-label-active) {
    color: #44444f !important;
  }

  .mat-ink-bar {
    background-color: #678aff !important;
  }

  .mat-tab-header {
    margin-bottom: 25px !important;
    border: none;
  }

  .field-title {
    margin: 0;
    padding: 0;
    color: #44444f;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .create-lesson-container {
    max-height: 100%;
    overflow-y: auto;

    .mat-tab-header,
    .button-row {
      margin: 0 16px;
    }
  }

  .button-row {
    display: flex;
    gap: 12px;

    .ezteach-calendar-popup__button {
      flex-grow: 1;
    }

    .btn__label {
      margin: 0;
    }
  }

  .button-actions {
    display: flex;
    gap: 16px;
  }

  .button-row .btn:first-child {
    margin-right: 5px;
  }

  .mat-tab-group {
    margin-bottom: 15px;

    &.mobile {
      margin-bottom: auto;
      border: none;
    }
  }

  .select-custom {
    height: 44px;
  }

  .policy-block,
  .privacy-block {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: #44444f;
    font-size: 16px;

    mat-radio-group {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .policy-block {
    margin-bottom: 20px;
  }

  .access-block {
    display: flex;
    flex-direction: row;
    margin-right: 8px;

    &__label {
      flex: 1;
    }

    margin-bottom: 8px;
  }

  .privacy-block {
    margin-bottom: 13px;

    &.mobile {
      margin-bottom: auto;
    }
  }

  .mat-radio-container {
    width: 16px !important;
    height: 14px !important;
  }

  .mat-radio-inner-circle {
    width: 14px !important;
    height: 14px !important;
  }

  .mat-radio-outer-circle {
    width: 14px !important;
    height: 14px !important;
  }

  .mat-radio-label-content {
    padding-left: 5px !important;
  }

  .info-icon-block {
    vertical-align: bottom;
  }

  .lesson-info-block {
    margin-bottom: 15px;

    &.mobile {
      margin-bottom: auto;
      border: none;
    }
  }

  .created-block-mobile {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .lesson-info {
    margin-bottom: 15px;
  }

  .mat-form-field-underline {
    display: none;
  }

  .info-icon {
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: bottom;
  }

  .btn {
    height: 44px;
  }

  .tooltip {
    padding: 16px !important;
    background: #ffffff;
    color: #44444f !important;
    font-size: 14px;
    margin: 0;
    border-radius: 8px;
    box-shadow: 0px 0px 15px 0px #0000001f;
  }

  .mat-input-element {
    text-overflow: ellipsis;
  }

  .edit {
    justify-content: flex-end;
    margin-bottom: 10px;
    margin-right: 5px;
  }

  .edit-slot {
    justify-content: space-between;
    align-items: baseline;
  }

  .field-title__edit-slot {
    margin-bottom: 10px;
  }

  .api-error-message {
    padding: 5px;
    line-height: 15px;
    margin-top: -10px;
    font-size: 11px;
    color: #ed3434;
    position: relative;

    img {
      display: none;
    }

    &:hover {
      cursor: pointer;

      img {
        display: block;
        position: absolute;
        width: 10px;
        right: 5px;
        top: 10px;
      }
    }
  }

  .shorten-link {
    mat-form-field {
      margin-bottom: 10px;
    }
  }
}

.ezteach-calendar-popup__time-field-error {
  font-size: 14px;
  color: #ed3434;
}