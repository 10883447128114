header,
:host {
  display: flex;
  background: #fff;
  color: #44444F;
  height: var(--headerHeight);
  align-items: center;
  padding: 0px 30px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

header .logotype,
:host .logotype {
  width: 110px;
  height: 51px;
}

header.isDisabled,
:host.isDisabled {
  cursor: not-allowed;
  opacity: 0.5;
  color: #c4c4c4;
  display: inline-block;
  pointer-events: none;
  text-decoration: none;
}

header.isDisabled svg,
:host.isDisabled svg {
  width: 100%;
  height: 100%;
  display: block;
}

header .nav,
:host .nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  gap: 20px;
  font-size: 14px;
  font-weight: 500;
}

header .nav__link,
:host .nav__link {
  color: $blueColor;
}

header.isDisabled,
:host.isDisabled {
  cursor: not-allowed;
  opacity: 0.5;
  color: #c4c4c4;
  display: inline-block;
  pointer-events: none;
  text-decoration: none;
}

header .toggle-menu,
:host .toggle-menu {
  display: none;
  outline: none;

  &:hover {
    border: none;
    outline: transparent;
  }
}

@media screen and (max-width: 1279.9px) {

  header,
  :host {
    // height: var(--headerHeightMobile);
    padding: 0 16px 0 0;
  }

  header .toggle-menu,
  :host .toggle-menu {
    background: $darkBlueColor;
    width: 60px;
    height: var(--headerHeightMobile);
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-shrink: 0;

    & svg,
    & svg {
      width: 24px;
      height: 24px;
    }

    &::after {
      width: 20px;
      height: 100%;
      display: block;
      content: ' ';
      position: absolute;
      right: 0px;
      top: 0;
      background: #fff;
      border-radius: 0 0 0 20px;
    }
  }

  header .btn,
  :host .btn {
    display: none;
  }

  header .header__inner-wrapper,
  :host .header__inner-wrapper {
    justify-content: flex-end;

    & .header__right_group {
      max-width: calc(100%/2 - 30px);
    }
  }

  header .logotype,
  :host .logotype {
    height: 44px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.header__inner-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 1002;
}

.header__inner-wrapper .header__right_group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.belt-icon-wrapper {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.header-avatar {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  background-color: #ffc849;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

.header-avatar .avatar-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.header-divider {
  height: 28px;
  width: 1px;
  background-color: #d9d9d9;
}

.hideHeader {
  display: none;
}